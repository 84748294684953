import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import {BrowserRouter as Router, Routes, Route, Switch} from 'react-router-dom';
import Home from './pages/home/home';
// import About from './pages/about/about';


function App() {
  return (
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/home" element={<Home/>}/>
        </Routes>
      </Router>
  );
}

export default App;