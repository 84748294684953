import React, {useEffect, useState} from 'react';
import {
    Nav, NavLink, NavMenu,
} from './NavbarElements';
import './Navbar.css';
import {scroller} from 'react-scroll'
import {Button} from "@mui/material";

const Navbar = () => {

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 90) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (<>
        <Nav className={colorChange ? 'navbar colorChange' : 'navbar'}>

            <NavLink onClick={() => scroller.scrollTo('home', {
                smooth: true, offset: -70, duration: 500,
            })}>
                <img src={require('../../images/crystaLava.png')} alt='logo'
                     className="logo"/>
            </NavLink>

            <NavMenu>
                {width > 900 ? <NavLink onClick={() => scroller.scrollTo('home', {
                    smooth: true, offset: -70, duration: 500,
                })} activeStyle useEffect>
                    HOME
                </NavLink> : null}


                {width > 900 ?

                    <NavLink onClick={() => scroller.scrollTo('description', {
                        smooth: true, offset: -70, duration: 500,
                    })} activeStyle>
                        PRODUCT
                    </NavLink> : null}

                {width > 900 ? <NavLink onClick={() => scroller.scrollTo('features', {
                    smooth: true, offset: -70, duration: 500,
                })} activeStyle>
                    FEATURES
                </NavLink> : null}

                <Button variant="contained"
                        href="https://www.amazon.com/CrystaLava-Colored-Porcelain-Coffee-Silicone/dp/B0BNZWDSZC/ref=sr_1_2?crid=L65QXXJIUVGT&keywords=crystalava&qid=1675052818&sprefix=crystalav%2Caps%2C164&sr=8-2"
                        sx={{
                            borderRadius: 28,
                            marginBottom: 1,
                            backgroundColor: "#ff6622",
                            fontSize: "16px",
                            lineHeight: "40px",
                            textAlign: "center",
                            padding: "4px 32px",
                        }}
                        style={{
                            fontFamily: 'Chalkboard', fontSize: "16px", fontWeight: 'bold'
                        }}
                >Purchase Now</Button>
            </NavMenu>
        </Nav>
    </>);
};

export default Navbar;

