import {FaBars} from 'react-icons/fa';
import {NavLink as Link} from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  background-color: #f8fbfd;
  height: 110px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((70vw - 1000px) / 2);
  z-index: 10;
  margin-top: 0.1px;
  overflow: hidden;
  position: sticky;
  top: 0;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease
  }

`;

export const NavLink = styled(Link)`
  //fontFamily: 'Chalkboard', fontSize: "16px", fontWeight : 'bold'

  color: #8F969D;
  border-bottom: aquamarine;
  font-family: "Noto Sans Oriya";
  font-size: 20px;
  font-weight: bold;
  //flex: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 2rem;
  height: 100%;
  cursor: pointer;
  

  &:hover {
    color: darkgrey;
  }


`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  font-size: large;
  font-weight: bold;
  padding: 50px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;