import React, {useEffect, useState} from 'react';
import './home.css';
import MyFooter from '../../components/Footer/myfooter';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import Grid from '@mui/material/Grid';
import {ReactComponent as Lid} from '../../images/lidSVG.svg';
import {ReactComponent as Dish} from '../../images/dish.svg';
import {ReactComponent as Cup} from '../../images/cup.svg';
import {ReactComponent as Nat} from '../../images/Nat.svg';
import {Button} from "@mui/material";

const Home = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showItem, setShowItem] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            if (width > 600) {
                setShowItem(true);
            } else {
                setShowItem(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [width]);


    return (<>

        <box sx={{flexGrow: 1}} className="home-grid1" id="home">
            <div className="home-box">
                <Grid container spacing={{xs: 1, md: 8, xl: 0}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <h3 className="home-header">The Combination of Luxury and Vitality</h3>
                        <div className="home-p1">
                            <p style={{textAlign: 'center'}}> Introduce a touch of elegance to your morning routine with
                                our set of four porcelain mugs. Made with the highest quality materials, each mug is
                                handcrafted to perfection, ensuring durability and longevity. The sleek, modern design
                                is perfect for any occasion, whether you're enjoying a casual cup of coffee with friends
                                or hosting a formal dinner party. These mugs are microwave and dishwasher safe, making
                                them easy to clean and maintain. Each set includes 4 mugs, making them perfect for
                                sharing with family and friends. Upgrade your kitchen collection with these beautiful
                                porcelain mugs. Order now and elevate your
                                drinking experience.
                            </p>
                            {width < 600 ? <Button variant="contained"
                                                   href="https://www.amazon.com/CrystaLava-Colored-Porcelain-Coffee-Silicone/dp/B0BNZWDSZC/ref=sr_1_2?crid=L65QXXJIUVGT&keywords=crystalava&qid=1675052818&sprefix=crystalav%2Caps%2C164&sr=8-2"
                                                   sx={{
                                                       borderRadius: 28,
                                                       marginBottom: 2,
                                                       backgroundColor: "#ff6622",
                                                       fontSize: "16px",
                                                       lineHeight: "40px",
                                                       textAlign: "center",
                                                       padding: "4px 32px",
                                                   }}
                                                   className="purchaseButton"
                                                   style={{
                                                       fontFamily: 'Chalkboard', fontSize: "16px", fontWeight: 'bold'
                                                   }}
                            >Purchase Now</Button> : null}
                        </div>
                        <div style={{textAlign: 'center', paddingBottom: '2%'}}><img className="main-image"
                                                                                     src={require('../../images/mugs4.jpg')}
                                                                                     alt="mainMug"/>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </box>

        {/* Start of Container 2*/}
        <box sx={{flexGrow: 1}} className="grid2" id="description">

            <Grid container spacing={{xs: 1, md: 1, xl: 4}}>
                <Grid Grid item xs={12} sm={12} md={12} xl={12}>
                    <h3 className="second-header">Product Information</h3>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div className="list-container">
                        <ul className="list-service" style={{fontFamily: 'Roboto'}}>
                            <li>
                                High quality porcelain: CrystaLava uses grade A porcelain (the highest grade of
                                porcelain)
                                to maximize durability. Resistant to scratches and easy to clean; dried or messy drinks
                                easily rinse out. dishwasher, microwave (the mug only) and freezer safe.
                            </li>
                            <li>
                                Great for all types of drinks: use these vibrant colored thermoses for a wide variety of
                                drinks. Excellent choice for coffee, tea, green juices, hot chocolate, cappuccino and so
                                much more.
                            </li>
                            <li>
                                Tumbler mugs great for taking your drink to-go, with comfortable handle to keep fingers
                                away
                                from the hot mug. Maximum capacity is 16 fluid ounces, Mugs comfortably hold 14-15 fluid
                                oz.
                            </li>
                            <li>Made of durable lead
                                free porcelain: our porcelain is safe for daily use and won't absorb odor or bacteria.
                            </li>
                            <li>Silicone lids: reusable,
                                flexible and soft food grade silicone material with lovely colors for your happy coffee
                                time, perfect for on the go. spill-proof lid with drinking hole and air intake.Keeps
                                your drink hot/cold for longer. dishwasher safe (not microwave safe).
                            </li>
                            {/*<CheckCircleIcon style={{paddingRight: '1%', fill: '#ff6622'}}/>*/}
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Carousel className="grid5" autoPlay={true} infiniteLoop={true} interval={10000}
                              dynamicHeight={true}
                              showThumbs={true}
                              thumbWidth={100}
                    >
                        <div>
                            <img
                                src={require('../../images/slide1.jpg')}
                                alt="mainMug"/>
                        </div>
                        <div>
                            <img
                                src={require('../../images/slide2.jpeg')}
                                alt="mainMug"/>
                        </div>
                        <div>
                            <img
                                src={require('../../images/glasseswithLid.jpg')}
                                // style={{maxHeight: '700px', maxWidth: '2000px'}}
                                alt="mainMug"/>
                        </div>
                        <div>
                            <img
                                src={require('../../images/slide4.jpg')}
                                alt="mainMug"/>
                        </div>
                        <div style={{height: '600px'}}>
                            <img
                                src={require('../../images/slide5.jpg')}
                                alt="mainMug"/>
                        </div>
                        <div >
                            <img
                                src={require('../../images/slide6.jpg')}
                                alt="mainMug"/>
                        </div>
                    </Carousel>
                </Grid>
            </Grid>
        </box>

        {/* Start of Container 3*/}

        <box sx={{flexGrow: 1}} className="grid3" id='features'>
            <Grid container spacing={{xs: 1, md: 3, xl: 0}}>
                <Grid item xs={12} sm={12} md={12}>
                    <h1 className="services-h2" style={{textAlign: 'center'}}>Our Product</h1>
                    <hr style={{width: '24%', paddingBottom: '1px', color: 'gray', backgroundColor: '#3CAFE1'}}/>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <div style={{textAlign: 'center', paddingBottom: '10%'}}><Dish
                        style={{height: '40%', width: '40%'}}/></div>
                    <h3 className="services-textH">DISH WASHER SAFE</h3>
                    <p className="services-textP">Mugs fit easily in the top rack of the dishwasher</p>
                </Grid> <Grid item xs={4} sm={4} md={4}>
                <div style={{textAlign: 'center', paddingBottom: '7%'}}><Cup style={{height: '40%', width: '40%'}}/>
                </div>
                <h3 className="services-textH">CAPACITY LIMIT</h3>
                <p className="services-textP">Normal capacity is 14-15oz with maximum capacity of 16 U.S fluid
                    ounces.</p>
            </Grid> <Grid item xs={4} sm={4} md={4}>
                <div style={{textAlign: 'center', paddingBottom: '7%'}}><Lid style={{height: '40%', width: '40%'}}/>
                </div>
                <h3 className="services-textH">SILICONE LIDS</h3>
                <p className="services-textP">Food grade silicone material with lovely colors.
                    Spill-proof.
                    Dishwasher safe.</p>
            </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <div style={{textAlign: 'center'}}><Nat className="nat"/>
                    </div>
                    <h3 className="services-textH">LEAD FREE</h3>
                    <p className="services-textP">Our porcelain is safe for daily use and won't absorb odor or
                        bacteria</p>
                </Grid>
            </Grid>
        </box>


        <MyFooter></MyFooter>

    </>);
};

export default Home;


