import React from 'react';
import './myfooter.css';


const MyFooter = () => {
    return (<>


            <div className="hr-line"></div>

            <footer className="footer">

                <p className="footer-p1" href="mailto:info@crystalava.com">info@crystalava.com <br/>
                    <a className="footer-p1">©{new Date().getFullYear()} crystaLava</a></p>
            </footer>
        </>

    );
};

export default MyFooter;
